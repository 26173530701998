@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/core/lib/scss/variables";

.App {
  min-height: 100vh;
}

.radius0 {
  border-radius: 0;
}

.menubar {
  a {
    text-decoration: none;
  }
}

.bp3-dark .menubar a {
  color: $pt-dark-heading-color;
}

// dark theme messes up CKEditor color, so we reset it here
.ck-content {
  color: black;
}

// ckeditor predefined height on the news create page
.newsCreate {
  .ck-content {
    height: 600px;
  }
}

.popover {
  max-height: 400px;
  overflow-y: auto;
}

.h-100 {
  height: 100%;
}
.transition-200ms {
  transition: 200ms;
}

.formHelperRight {
  div[class="bp3-form-helper-text"] {
    text-align: right;
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
